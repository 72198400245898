import http from './http'
import config from '@/utils/config'

const exchangeRate = `/backEnd/api/${config.supplyCode}/ExchangeRate`
function getExchangeRatePages(params) {
  return http.post(`${exchangeRate}/getExchangeRatePages`, params)
}
function addExchangeRate(params) {
  return http.post(`${exchangeRate}/addExchangeRate`, params)
}
function updateExchangeRate(params) {
  return http.post(`${exchangeRate}/updateExchangeRate`, params)
}

const bankAccount = `/backEnd/api/${config.supplyCode}/BankAccount`

function switchBankAccount(params) {
    return http.get(`${bankAccount}/switchBankAccount`, params)
}
function getBankAccountCompany(params) {
    return http.get(`${bankAccount}/GetBankAccountCompany`, params)
}
function deleteBankAccountCompany(params) {
    return http.get(`${bankAccount}/deleteBankAccountCompany`, params)
}
function getBankAccountPageList(params) {
    return http.post(`${bankAccount}/GetBankAccountPageList`, params)
}
function insertBankAccount(params) {
    return http.post(`${bankAccount}/insertBankAccount`, params)
}
function insertBankAccountCompany(params) {
    return http.post(`${bankAccount}/insertBankAccountCompany`, params)
}

const fee = `/backEnd/api/${config.supplyCode}/fee`
function switchFeeName(params) {
  return http.get(`${fee}/switchFeeName`, params)
}
function getFeeTypeList(params) {
  return http.get(`${fee}/GetFeeTypeList`, params)
}
function GetFeeNameList(params) {
  return http.get(`${fee}/GetFeeNameList`, params)
}
function getFeeNamePageList(params) {
  return http.post(`${fee}/GetFeeNamePageList`, params)
}
function insertFeeName(params) {
  return http.post(`${fee}/insertFeeName`, params)
}
function insertFeeType(params) {
  return http.post(`${fee}/insertFeeType`, params)
}
function deleteFeeName(params) {
  return http.delete(`${fee}/deleteFeeName`, params)
}
function updateFeeName(params) {
  return http.get(`${fee}/updateFeeName`, params)
}

const Quote = `/backEnd/api/${config.supplyCode}/Quote`
function originCity(params) {
  return http.get(`${Quote}/originCity`, params)
}
function availableAccounts(params) {
  return http.get(`${Quote}/availableAccounts`, params)
}
function quotes(params) {
  return http.post(`${Quote}/quotes`, params)
}
function getTariffCalculate(params) {
  return http.post(`${Quote}/getTariffCalculate`, params)
}
function importTariffCalculateExcel(params) {
  return http.upload(`${Quote}/importTariffCalculateExcel`, params)
}
function downloadTariffCalculateExcel(params) {
  return http.download(`${Quote}/downloadTariffCalculateExcel`, params)
}
function getTruckRate(params) {
  return http.post(`${Quote}/getTruckRate`, params)
}
function addressValidation(params) {
  return http.post(`${Quote}/addressValidation`, params)
}

const Order = `/backEnd/api/${config.supplyCode}/Order`
function getTrackPages(params) {
  return http.post(`${Order}/getTrackPages`, params)
}
function trackDetail(params) {
  return http.get(`${Order}/trackDetail`, params)
}
function getOrderPageList(params) {
  return http.post(`${Order}/getOrderPageList`, params)
}
function cancel(params) {
  return http.delete(`${Order}/cancel`, params)
}
function deleteOrder(params) {
  return http.delete(`${Order}/delete`, params)
}
function trackOverview(params) {
  return http.post(`${Order}/trackOverview`, params)
}
function getEstimateRate(params) {
  return http.get(`${Order}/getEstimateRate`, params)
}
function batchUploadData(params) {
  return http.get(`${Order}/batchUploadData`, params)
}
function shipment(params) {
  return http.post(`${Order}/shipment`, params)
}
function getOrderDetail(params) {
  return http.get(`${Order}/getOrderDetail`, params)
}
function getOrderProductPageList(params) {
  return http.post(`${Order}/getOrderProductPageList`, params)
}
function getOrderDimensionPageList(params) {
  return http.post(`${Order}/getOrderDimensionPageList`, params)
}
function existsTrackingNumber(params) {
  return http.get(`${Order}/existsTrackingNumber`, params)
}
function getReprintLabelPageList(params) {
  return http.post(`${Order}/getReprintLabelPageList`, params)
}
function deleteReprintLabel(params) {
  return http.post(`${Order}/deleteReprintLabel`, params)
}
function reprintLabel(params, companyId) {
  return http.upload(`${Order}/reprintLabel?companyId=${companyId}`, params)
}
function checkReprintNumbers(params, companyId) {
  return http.post(`${Order}/checkReprintNumbers?companyId=${companyId}`, params)
}
function uploadReprintLabel(params, companyId) {
  return http.upload(`${Order}/uploadReprintLabel?companyId=${companyId}`, params)
}
function getUnboundMblOrders(params, companyId) {
  return http.post(`${Order}/getUnboundMblOrders?companyId=${companyId.companyId}`, params)
}
function refreshOrderTrack(params) {
  return http.get(`${Order}/refreshOrderTrack`, params)
}

const Finance = `/backEnd/api/${config.supplyCode}/Finance`
function getSurchargePageList(params) {
  return http.post(`${Finance}/getSurchargePageList`, params)
}
function markSurchargeException(params) {
  return http.post(`${Finance}/markSurchargeException`, params)
}
function markSurchargeNormal(params) {
  return http.post(`${Finance}/markSurchargeNormal`, params)
}
function addSurcharge(params) {
  return http.post(`${Finance}/addSurcharge`, params)
}
function readSurchargeFile(params) {
  return http.upload(`${Finance}/readSurchargeFile`, params)
}
function getRateAdjustment(params) {
  return http.get(`${Finance}/getRateAdjustment`, params)
}
function getRateAdjustmentDetail(params) {
  return http.post(`${Finance}/getRateAdjustmentDetail`, params)
}
function exportRateAdjustmentDetail(params) {
  return http.download(`${Finance}/exportRateAdjustmentDetail`, params, 'get')
}

const Payable = `/backEnd/api/${config.supplyCode}/Payable`
function getPayablePages(params) {
  return http.post(`${Payable}/getPayablePages`, params)
}
function updatePayableDetail(params) {
  return http.post(`${Payable}/updatePayableDetail`, params)
}
function createSharePayableInvoice(params) {
  return http.upload(`${Payable}/createSharePayableInvoice`, params)
}
function createPayable(params) {
  return http.upload(`${Payable}/createPayable`, params)
}
function reviewedPayableInvoice(params) {
  return http.get(`${Payable}/reviewedPayableInvoice`, params)
}
function deletePayableInvoice(params) {
  return http.get(`${Payable}/deletePayableInvoice`, params)
}
function repeatReviewedPayableInvoice(params) {
  return http.get(`${Payable}/repeatReviewedPayableInvoice`, params)
}
function getPayableDetail(params) {
  return http.get(`${Payable}/getPayableDetail`, params)
}
function getSupplierFeeIdentifyTemplate(params) {
  return http.get(`${Payable}/getSupplierFeeIdentifyTemplate`, params)
}
function getSupplierFeeIdentifyTemplateDetail(params) {
  return http.get(`${Payable}/getSupplierFeeIdentifyTemplateDetail`, params)
}
function modifySupplierFeeIdentify(params) {
  return http.post(`${Payable}/modifySupplierFeeIdentify`, params)
}
function delSupplierFeeIdentify(params) {
  return http.get(`${Payable}/delSupplierFeeIdentify`, params)
}
function SupplierPayableFeeIdentify(params) {
  return http.get(`${Payable}/SupplierPayableFeeIdentify`, params)
}

const Receivable = `/backEnd/api/${config.supplyCode}/Receivable`
function getReceivablePages(params) {
  return http.post(`${Receivable}/getReceivablePages`, params)
}
function completeReceivable(params) {
  return http.get(`${Receivable}/completeReceivable`, params)
}
function getReceivableDetail(params) {
  return http.get(`${Receivable}/getReceivableDetail`, params)
}
function updateReceivableDetail(params) {
  return http.post(`${Receivable}/updateReceivableDetail`, params)
}
function reviewedReceivableDetails(params) {
  return http.post(`${Receivable}/reviewedReceivableDetails`, params)
}

const ReconInfomation = `/backEnd/api/${config.supplyCode}/ReconInfomation`
function getReconInvocePages(params) {
  return http.post(`${ReconInfomation}/getReconInvocePages`, params)
}
function addReconFeeNameMapping(params) {
  return http.post(`${ReconInfomation}/addReconFeeNameMapping`, params)
}
function updateReconFeeNameMapping(params) {
  return http.post(`${ReconInfomation}/updateReconFeeNameMapping`, params)
}
function getReconFeeNameMappingList(params) {
  return http.get(`${ReconInfomation}/getReconFeeNameMappingList`, params)
}
function deleteReconFeeNameMapping(params) {
  return http.get(`${ReconInfomation}/deleteReconFeeNameMapping`, params)
}
function deleteReconInvoce(params) {
  return http.get(`${ReconInfomation}/deleteReconInvoce`, params)
}
function existsReconInvoiceNo(params) {
  return http.get(`${ReconInfomation}/existsReconInvoiceNo`, params)
}
function reconciliation(params) {
  return http.get(`${ReconInfomation}/reconciliation`, params)
}
function ReconInfoImport(params) {
  return http.post(`${ReconInfomation}/ReconInfoImport`, params)
}
function getReconDetailPages(params) {
  return http.post(`${ReconInfomation}/getReconDetailPages`, params)
}
function ReconDetailReview(params) {
  return http.post(`${ReconInfomation}/ReconDetailReview`, params)
}
function getReconciliationResult(params) {
  return http.get(`${ReconInfomation}/getReconciliationResult`, params)
}

const CustomerInvoice = `/backEnd/api/${config.supplyCode}/CustomerInvoice`
function getPages(params) {
  return http.post(`${CustomerInvoice}/getPages`, params)
}
function generateInvoices(params) {
  return http.post(`${CustomerInvoice}/generateInvoices`, params)
}
function reviewInvoice(params) {
  return http.get(`${CustomerInvoice}/reviewInvoice`, params)
}
function getCustomerInvoiceStartDate(params) {
  return http.get(`${CustomerInvoice}/getCustomerInvoiceStartDate`, params)
}

const Defray = `/backEnd/api/${config.supplyCode}/Defray`
function getDefrayPageList(params) {
  return http.post(`${Defray}/getDefrayPageList`, params)
}
function getWaitDefrayInvoice(params) {
  return http.post(`${Defray}/getWaitDefrayInvoice`, params)
}
function getDefrayInvoice(params) {
  return http.get(`${Defray}/getDefrayInvoice`, params)
}
function deleteDefray(params) {
  return http.get(`${Defray}/deleteDefray`, params)
}
function getDefrayReviewRecord(params) {
  return http.get(`${Defray}/getDefrayReviewRecord`, params)
}
function addDefray(params) {
  return http.post(`${Defray}/addDefray`, params)
}
function checkAddDefray(params) {
  return http.post(`${Defray}/checkAddDefray`, params)
}
function mergeDefrayPDF(params) {
  return http.post(`${Defray}/mergeDefrayPDF`, params)
}
function reviewDefray(params) {
  return http.upload(`${Defray}/reviewDefray`, params)
}
function getDefrayPaymentPage(params) {
  return http.post(`${Defray}/getDefrayPaymentPage`, params)
}
function getNotReviewDefrayInvoice(params) {
  return http.post(`${Defray}/getNotReviewDefrayInvoice`, params)
}
function paymentDefray(params) {
  return http.upload(`${Defray}/paymentDefray`, params)
}
function getDefrayPaymentDetail(params) {
  return http.get(`${Defray}/getDefrayPaymentDetail`, params)
}

const Analysis = `/backEnd/api/${config.supplyCode}/Analysis`
function grossProfitClePicPages(params) {
  return http.post(`${Analysis}/grossProfitClePicPages`, params)
}
function grossProfitDeliveryPages(params) {
  return http.post(`${Analysis}/grossProfitDeliveryPages`, params)
}
function grossProfitClePicDetail(params) {
  return http.post(`${Analysis}/grossProfitClePicDetail`, params)
}
function grossProfitCloseClePic(params) {
  return http.post(`${Analysis}/grossProfitCloseClePic`, params)
}
function grossProfitDeliveryDetail(params) {
  return http.post(`${Analysis}/grossProfitDeliveryDetail`, params)
}
function grossProfitExportClePicOrigin(params) {
  return http.download(`${Analysis}/grossProfitExportClePicOrigin`, params, 'post')
}
function grossProfitExportClePicProfit(params) {
  return http.download(`${Analysis}/grossProfitExportClePicProfit`, params, 'post')
}
function grossProfitExportClePicPayble(params) {
  return http.download(`${Analysis}/grossProfitExportClePicPayble`, params, 'post')
}
function grossProfitCloseDelivery(params) {
  return http.post(`${Analysis}/grossProfitCloseDelivery`, params)
}

const TruckQuote = `/backEnd/api/${config.supplyCode}/TruckQuote`
function getTruckQuotePages(params) {
  return http.post(`${TruckQuote}/getTruckQuotePages`, params)
}
function addOrUpdateTruckQuote(params) {
  return http.post(`${TruckQuote}/addOrUpdateTruckQuote`, params)
}
function getTruckQuoteProducts(params) {
  return http.get(`${TruckQuote}/getTruckQuoteProducts`, params)
}
function addTruckQuotePrice(params) {
  return http.post(`${TruckQuote}/addTruckQuotePrice`, params)
}
function getTruckQuotePriceList(params) {
  return http.get(`${TruckQuote}/getTruckQuotePriceList`, params)
}

export {
  getExchangeRatePages,
  addExchangeRate,
  updateExchangeRate,
  switchBankAccount,
  getBankAccountCompany,
  deleteBankAccountCompany,
  getBankAccountPageList,
  insertBankAccount,
  insertBankAccountCompany,
  switchFeeName,
  getFeeTypeList,
  getFeeNamePageList,
  insertFeeName,
  insertFeeType,
  originCity,
  availableAccounts,
  quotes,
  getTrackPages,
  trackDetail,
  getTariffCalculate,
  importTariffCalculateExcel,
  downloadTariffCalculateExcel,
  getTruckRate,
  addressValidation,
  getOrderPageList,
  cancel,
  deleteOrder,
  trackOverview,
  getEstimateRate,
  batchUploadData,
  shipment,
  getOrderDetail,
  getOrderProductPageList,
  getOrderDimensionPageList,
  getSurchargePageList,
  getReconInvocePages,
  addReconFeeNameMapping,
  getReconFeeNameMappingList,
  deleteReconFeeNameMapping,
  ReconInfoImport,
  deleteReconInvoce,
  getReconDetailPages,
  updateReconFeeNameMapping,
  ReconDetailReview,
  existsReconInvoiceNo,
  reconciliation,
  getPayablePages,
  reviewedPayableInvoice,
  deletePayableInvoice,
  repeatReviewedPayableInvoice,
  createSharePayableInvoice,
  createPayable,
  getPayableDetail,
  updatePayableDetail,
  GetFeeNameList,
  getReceivablePages,
  getReceivableDetail,
  updateReceivableDetail,
  reviewedReceivableDetails,
  completeReceivable,
  markSurchargeNormal,
  markSurchargeException,
  generateInvoices,
  reviewInvoice,
  existsTrackingNumber,
  addSurcharge,
  readSurchargeFile,
  getRateAdjustment,
  getRateAdjustmentDetail,
  getDefrayPageList,
  getWaitDefrayInvoice,
  getDefrayInvoice,
  addDefray,
  checkAddDefray,
  deleteDefray,
  getDefrayReviewRecord,
  reviewDefray,
  mergeDefrayPDF,
  getPages,
  getReprintLabelPageList,
  getCustomerInvoiceStartDate,
  deleteReprintLabel,
  deleteFeeName,
  reprintLabel,
  checkReprintNumbers,
  uploadReprintLabel,
  getUnboundMblOrders,
  getReconciliationResult,
  grossProfitClePicPages,
  grossProfitDeliveryPages,
  grossProfitClePicDetail,
  grossProfitCloseClePic,
  grossProfitDeliveryDetail,
  grossProfitExportClePicOrigin,
  grossProfitExportClePicProfit,
  grossProfitExportClePicPayble,
  grossProfitCloseDelivery,
  getDefrayPaymentPage,
  getNotReviewDefrayInvoice,
  paymentDefray,
  updateFeeName,
  getDefrayPaymentDetail,
  getTruckQuotePages,
  addOrUpdateTruckQuote,
  getTruckQuoteProducts,
  addTruckQuotePrice,
  getTruckQuotePriceList,
  refreshOrderTrack,
  getSupplierFeeIdentifyTemplate,
  getSupplierFeeIdentifyTemplateDetail,
  modifySupplierFeeIdentify,
  delSupplierFeeIdentify,
  SupplierPayableFeeIdentify,
  exportRateAdjustmentDetail
}
